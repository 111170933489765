import { IPageType } from '@@types/moduleTypes'
import Layout from '@layouts/NonScrollableLayout'
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { NewsLetterFormPage } from '../Form/NewsletterFormPage'
import { StatisticPage } from '../StatisticPage/StatisticPage'
import { observer } from 'mobx-react-lite'

const typeViews = [{ name: 'Основное' }, { name: 'Статистика' }]

export const NewsletterPage = observer(({ isAdmin }: IPageType) => {
  const [viewType, setViewType] = useState(typeViews[0])
  const { id } = useParams()

  const onSelectButtonClickHandler = (e: SelectButtonChangeEvent) => {
    setViewType(e.value)
  }

  return (
    <Layout className='!p-0'>
      <div className='flex h-full w-full flex-col bg-white px-4 py-[20px]'>
        {id && (
          <SelectButton
            value={viewType}
            onChange={(e) => onSelectButtonClickHandler(e)}
            options={typeViews}
            optionLabel='name'
            className='selectButton mb-[20px]'
            allowEmpty={false}
          />
        )}
        {viewType === typeViews[0] && <NewsLetterFormPage isAdmin={isAdmin} />}
        {viewType === typeViews[1] && <StatisticPage />}
      </div>
    </Layout>
  )
})
