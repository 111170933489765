import { ApiNewsLetter } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import FormTextArea from '@components/ui/Form/FormTextArea'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { AppRoutesPaths, navigateTo } from '@config/navigation'
import { ToastContext } from '@context/toastContext'
import { useLogic, useStore } from '@hooks/storeHook'
import Layout from '@layouts/NonScrollableLayout'
import { IStories } from '@modules/Advertising/Stories/StoriesPage'
import { createFormData } from '@utils/createDataForm'
import times from '@utils/times'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { newsletterTriggers } from '../NewsLetters'
import { TNewsLetterForm } from '../types'
import FormMultiselect from '@components/ui/Form/FormMultiselect/FormMultiselect'

export const NewsLetterFormPage: FC<IStories> = observer(({ isAdmin }) => {
  const methods = useForm<TNewsLetterForm>({
    defaultValues: {
      name: '',
      trigger: newsletterTriggers[0],
      text: '',
      date: undefined,
      time: '',
      tickets: []
    },
  })
  const logic = useLogic()
  const context = useContext(ToastContext)

  const trigger = useWatch({ control: methods.control, name: 'trigger' })

  const {
    auth: { selectedPark },
    ticket: { activeTickets }
  } = useStore()

  const { id } = useParams()

  useEffect(()=>{
    console.log(activeTickets)
  },[activeTickets])

  const [triggerContent, setTriggerContent] = useState<ReactNode>(null)
  const [initialValue, setInitialValue] = useState<ApiNewsLetter | undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const [count, setCount] = useState(0)
  const [percentage, setPercentage] = useState(0)

  const onCreate = async (data: TNewsLetterForm) => {
    const formData = createFormData({
      name: data.name,
      trigger_type: data.trigger.id,
      active: 1,
      text: data.text,
      execute_at: moment(data.date)
        .set({
          hour: parseInt(data.time.split(':')[0]),
          minute: parseInt(data.time.split(':')[1]),
          seconds: 0,
        })
        .format('YYYY-MM-DD HH:mm:ss'),
    })

    !isAdmin && formData.append('park_id', String(selectedPark!.id))

    if (data.trigger.id === 6){
      data.tickets.forEach((e,i)=>{
        formData.append(`tickets[${i}]`,String(e.id))
      })
    }

    if (data.triggerNumber) formData.append('trigger_value', String(data.triggerNumber))

    const { status, errors } = isAdmin
      ? await logic.createAdminNewsletter(formData)
      : await logic.createNewsletter(formData)
    if (status) navigateTo(isAdmin ? AppRoutesPaths.AdminPaneNewsletter : AppRoutesPaths.AdvertisingNewsletter)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const onEdit = async (data: TNewsLetterForm) => {
    const encodedText = encodeURIComponent(data.text)
    let query = `?name=${data.name}&trigger_type=${data.trigger.id}&text=${encodedText}&execute_at=${moment(data.date)
      .set({
        hour: parseInt(data.time.split(':')[0]),
        minute: parseInt(data.time.split(':')[1]),
        seconds: 0,
      })
      .format('YYYY-MM-DD HH:mm:ss')}`

    if (data.triggerNumber) query += `&trigger_value=${data.triggerNumber}`

    if (data.trigger.id === 6){
      data.tickets.forEach((e,i)=>{
        query += `&tickets[${i}]=${e.id}`
      })
    }

    const { status, errors } = isAdmin
      ? await logic.updateAdminNewsletter(query, +id!)
      : await logic.updateNewsletter(query, +id!)
    if (status) navigateTo(isAdmin ? AppRoutesPaths.AdminPaneNewsletter : AppRoutesPaths.AdvertisingNewsletter)
    else context?.toastShowHandler({ status: false, errors: errors })
  }

  const init = async () => {
    if (!selectedPark) return
    setLoading(true)

    await logic.loadTicket()
    if (id) {
      logic
        .loadNewsletterById(+id)
        .then((res) => setInitialValue(res.data))
        .finally(() => setLoading(false))
    } else setLoading(false)
  }

  useEffect(() => {
    init()
  }, [selectedPark])

  useEffect(() => {
    const initialTrigger = newsletterTriggers.find((e) => e.id === initialValue?.trigger_type)
    const content = triggerValueSwitcher(initialTrigger?.id)
    setTriggerContent(content)
    methods.reset({
      name: initialValue?.name,
      trigger: initialTrigger,
      triggerNumber: initialValue?.trigger_value ?? undefined,
      text: initialValue?.text,
      date: initialValue ? new Date(initialValue?.execute_at) : undefined,
      time: initialValue ? moment(initialValue?.execute_at).format('HH:mm') : '',
      tickets: initialValue ? activeTickets.filter((show) => initialValue.tickets?.some((e) => e.id === show.id)) : []
    })
    if (initialValue) {
      setCount(initialValue.count)
      setPercentage(initialValue.percentage ?? 0)
    }
  }, [initialValue])

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'trigger') {
        const content = triggerValueSwitcher(value.trigger?.id)
        setTriggerContent(content)
      }
    })

    return () => subscription.unsubscribe()
  }, [methods.watch])

  useEffect(() => {
    if (!initialValue) return

    const intervalId = setInterval(() => {
      const dateToCompare = moment(initialValue.execute_at)
      const now = moment()
      const diffInMinutes = dateToCompare.diff(now, 'minutes')

      if (
        Math.abs(diffInMinutes) <= 1 ||
        (initialValue.percentage && initialValue.percentage > 0 && initialValue.percentage < 100)
      ) {
        clearInterval(intervalId)
        const fetchIntervalId = setInterval(async () => {
          const { data } = await logic.loadNewsletterStateById(initialValue.id)

          if (!data.count || data.percentage === 100) {
            clearInterval(fetchIntervalId)
          } else {
            if (data.count) setCount(data.count)
            if (data.percentage !== undefined) setPercentage(data.percentage ?? 0)
          }
        }, 3000)

        return () => clearInterval(fetchIntervalId)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [initialValue])

  const triggerValueSwitcher = (triggerId: number | undefined) => {
    methods.setValue('triggerNumber', undefined)
    methods.setValue('date', undefined)
    methods.setValue('time', '')

    if (triggerId === 7 || triggerId === 8 || triggerId === 12 || triggerId === 13) {
      return (
        <FormInput
          className='text-field h-[36px] rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-grey focus:outline-none'
          containerClassName='col-start-2'
          title='Настрой триггера'
          name='triggerNumber'
          required
          type='number'
        />
      )
    } else if (triggerId === 10) {
      return (
        <div className='flex items-end gap-2'>
          <div className='flex flex-col gap-[4px]'>
            <UiText className='text-grey'>Дата и время</UiText>
            <Controller
              control={methods.control}
              name='date'
              rules={{ required: 'Date is required.' }}
              render={({ field, fieldState }) => (
                <Calendar
                  value={field.value}
                  onChange={field.onChange}
                  placeholder='дата'
                  showIcon={true}
                  dateFormat='dd.mm.yy'
                  locale='ru'
                  readOnlyInput={true}
                  className='worker w-[120px] shadow-none'
                  inputClassName={cn('shadow-none h-[38px]', fieldState.error && '!border-red')}
                  minDate={new Date()}
                />
              )}
            />
          </div>
          <div className='flex h-[38px] items-center gap-1 align-bottom'>
            <Controller
              control={methods.control}
              name='tickets'
              rules={{ required: 'Time is required.' }}
              render={({ field, fieldState }) => (
                <Dropdown
                  value={field.value}
                  placeholder='время'
                  className={cn('worker timeInput !w-[62px] border-green text-red', fieldState.error && '!border-red')}
                  panelClassName='worker-panel'
                  color='green'
                  options={times}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        </div>
      )
    }else if (triggerId === 6){
      return  <div className='flex gap-2 col-start-2 col-end-4'>
         <FormInput
          className='text-field w-[180px] h-[36px] rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-grey focus:outline-none'
          containerClassName='col-start-2'
          title='Настрой триггера'
          name='triggerNumber'
          required
          type='number'
        />
        <FormMultiselect name={'tickets'} options={activeTickets} optionLabel='name' placeholder='Выбрать билеты' title='Билеты' customSize='small' required/>
      </div>
    }else return <></>
  }

  return (
    <Layout
      title={initialValue ? 'Изменить рассылку' : 'Добавить рассылку'}
      loading={loading}
      showHeader={false}
      className='h-full !p-0'
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(initialValue ? onEdit : onCreate)} className='flex h-full w-full flex-col'>
          <div className='grid grid-cols-[repeat(3,233px)] gap-x-9 gap-y-4'>
            <FormInput
              className={`text-field h-[36px] rounded-[3px] border-[1px] border-solid border-green bg-white px-[8px] py-[10px] text-grey focus:outline-none`}
              containerClassName='col-start-1 col-end-3'
              title='Название'
              name='name'
              required
            />

            <div className='col-start-1 flex w-[100%] flex-col gap-[4px]'>
              <UiText className='text-grey'>Триггер</UiText>

              <Controller
                name='trigger'
                control={methods.control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={newsletterTriggers}
                    optionLabel='name'
                    placeholder='Выберите триггер'
                    className={`md:w-14rem worker w-full border-green ${fieldState.error ? '!border-red' : ''}`}
                    panelClassName='worker-panel'
                    color='green'
                    required
                  />
                )}
              />
            </div>

            {triggerContent}

            <div className='col-start-1 col-end-3 flex flex-col gap-3'>
              <FormTextArea
                name='text'
                title='Текст сообщения'
                rows={4}
                placeholder='%NAME%, добрый день!&#10;⠀&#10;Ваш код подтверждения: %PIN%'
                required
              />

              <ul className='m-0 list-inside p-0 text-base leading-5 text-grey'>
                Возможные автовставки:
                <li>%NAME% имя гостя</li>
                {trigger?.id !== 6 && <li>%POINTS% количество бонусных баллов за заказ</li>}
                {trigger?.id !== 13 && trigger?.id !== 6 && <li>%PIN% уникальный номер для подтверждения заказа</li>}
                <li>%BALANCE% баланс бонусных баллов у клиента</li>
                {trigger?.id === 12 && <li>%DATE_MONTH_X% - текущая дата +X месяцев</li>}
                {trigger?.id === 11 && <li>{'<br/>'} – конец одного сообщения (для нескольких) </li>}
              </ul>
            </div>
          </div>

          <footer className='mt-auto flex gap-2'>
            <UiButton className='hover:bg-green-[#16A34A] col-start-1 max-h-[36px] w-[233px] justify-center !text-sm !font-black uppercase'>
              {initialValue ? 'Изменить' : 'Добавить'}
            </UiButton>
            {initialValue && (
              <div className='flex flex-col justify-center gap-1'>
                <UiText className='text-grey'>Осталось обработать: {count}</UiText>
                {!!percentage && <UiText className='text-grey'>Процент обработанных заявок: {percentage}%</UiText>}
              </div>
            )}
          </footer>
        </form>
      </FormProvider>
    </Layout>
  )
})
